import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/Install/iPhone/IN-9010-9020/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "iphone-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iphone-app-installationsassistent",
        "aria-label": "iphone app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPhone App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera-in-9010-fhd--in-9020-fhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera-in-9010-fhd--in-9020-fhd",
        "aria-label": "hinzufügen einer neuen ip kamera in 9010 fhd  in 9020 fhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera (`}<em parentName="h3">{`IN-9010 FHD & IN-9020 FHD`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d2e81f218e78df04b8954be7c33d40e4/81315/iPhone_Wizard_Installation_9020_9010_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADLElEQVQ4y41UW2/jRBT2P+GRB14QL/xGHhACJP7NSstKW9g0W1K1iK22SZs4aXyNZzxX2zP+0BzHiYOKwNKnmTlz/M25R1IqlEUJ73q0rYf3ILSdh3M98rwE5xLWtsizDF2Qe6DvcdINYKxGXhwQJazDpmghtEPveyRJRui6Hl3rIFSLl6pDyh3iskHTemRZgeXyCZvNFut1TI8Z22FTtogYN6i4Ba8NhDCoKomqErSvSTbcBT1eNyRP0xIvLxl2u5RWxiSUakgvqmuNEZxrIgkI+xH1UV4dBPKcIY4TbDZ7bLfJiTTLKtKNAis/kkyJpudgZZoecH39B2E2u8HNzR0Wi3t8/HhL5/l8gdUqDhaeyV7DSJjnFebzWyK5vf0T799/wNXVDNfXC9zd/UXyz5+fz4RTFy/c/ccDQjQU5+DqdpuS5eHB8T46/3zp+mshmO6lbAiUtIk8ClnTyqLID9jthiDH8Z4wvG4gan3hfj2xaCQe7yOuHfasQ1Z3yHiDruvgnINznuC9h278qYyy7IAkKSimIbMPDyusVpuzy0IZJAVDySSyA4cQgkjC14d2QI/G9RBycC148CmusUsqPHx6xJs3b/Hu3RWVU7A+ktajVB7COqD3ZJ33jkgDYQARHmMVLHzeFkjSCsvHJ8zuYyzul0iTghIWsVrhJWfISg7GOKqKgTEGKSW874nYkMv2FPgsLU+JWMcZ9vvy7LIUBkqG4A6tN8W5VMaMGzCmUZSCVl5bHA4SFVNUIUNS/qX+XkPIdqs1OmNobZVGpw06raHEUHr/2SmjZYGsrAzmTxa/Ly1mqwEflha/PVqsUwMp9P8jDJOmURqLtcXXP3l8+b3HNz97fPuLx1c/eHzxncePv7aA1eO0mRKM02di4VEeiJ9Tg1UyYLk/rolBfhi8iKjluDmNqen5EgO5lhpmgvEsjuMvmlrET3NRXWR4yPLxbhy2XION2Q26R72ormsYJVGoHqUGGqOhlEbbttBaw9oG1loopSBFjUr3yCTQtRZCSGqEULNt25FO5I5d4XxPhZ3nOYoiB+eM5HTn3Gnv+x68FihIr0BVVaf7sP4NxHqI2gA0aQkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2e81f218e78df04b8954be7c33d40e4/e4706/iPhone_Wizard_Installation_9020_9010_d01.avif 230w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/d1af7/iPhone_Wizard_Installation_9020_9010_d01.avif 460w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/7f308/iPhone_Wizard_Installation_9020_9010_d01.avif 920w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/e1c99/iPhone_Wizard_Installation_9020_9010_d01.avif 1380w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/6e1e4/iPhone_Wizard_Installation_9020_9010_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d2e81f218e78df04b8954be7c33d40e4/a0b58/iPhone_Wizard_Installation_9020_9010_d01.webp 230w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/bc10c/iPhone_Wizard_Installation_9020_9010_d01.webp 460w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/966d8/iPhone_Wizard_Installation_9020_9010_d01.webp 920w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/445df/iPhone_Wizard_Installation_9020_9010_d01.webp 1380w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/87a8c/iPhone_Wizard_Installation_9020_9010_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2e81f218e78df04b8954be7c33d40e4/81c8e/iPhone_Wizard_Installation_9020_9010_d01.png 230w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/08a84/iPhone_Wizard_Installation_9020_9010_d01.png 460w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/c0255/iPhone_Wizard_Installation_9020_9010_d01.png 920w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/b1001/iPhone_Wizard_Installation_9020_9010_d01.png 1380w", "/en/static/d2e81f218e78df04b8954be7c33d40e4/81315/iPhone_Wizard_Installation_9020_9010_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d2e81f218e78df04b8954be7c33d40e4/c0255/iPhone_Wizard_Installation_9020_9010_d01.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Menü `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie den `}<strong parentName="li">{`Installationsassistenten`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie finden den `}<strong parentName="li">{`QR-Code`}</strong>{` in der `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Internet_Access/"
        }}>{`WebUI`}</a>{` (A), auf dem Etikett Ihrer Kamera (B) oder auf der Verpackung abgedruckt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/81315/iPhone_Wizard_Installation_9020_9010_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADA0lEQVQ4y42U208TQRjFtxdoSwmXcguXBx554MFHEx+ML/57JhqEaPxjhEAiUFuMaIhii11LL9vt7L2dvRzzzXbXrlBCk5OZnfT77Zk5344kpTKQsnlIkjRRMzMzqFQuYBomXO7Ccz14XgDfBzgP54zpUBQVkpTNIVUsQUqlJwMLBRyefoViA40eR98OwPoaZLkJ1/UF1HE4fvc4/X+ys1QqJcZ8Po/TsyoGHFBUC5ruoNVS0GjcQtMsGIYjROsPAiPl8wWcn1fhcoAxE7puo9tl6HT6YMyCrjvQNFusx8CFhQWsrq4KLS8vY2VlBaVSKXZIQM6Bft8UxYYxEPoHG3M4OzsrRIdfLBYTorX7gOQscjcOFUAqfCjhQqEQAwnSVw202yoURUPzTwftdi+Gxg7T6XQcxLj+B1JhrSaj9qsBWW6hdauIgO4Ao0QnOTw7q8J1gV5Ph6oa4vzIrdxoodnsJoFURA4XFxeFlpaWhOgldH7jQKXLBMg0h+I8KWl6QQJICZPLjY0NrK2tCW1tbYmznZubE20TATttFd+//cDV1TXqdVk4JreJULa3twWMxt3dXezs7MRr6+vriZTJ3efyBU6OP6FWawjQuBIp53I5sWXqQ4LclzIVUVMTjMZoqwmHVDQ/Py+2TSASzWmNYOMOFdVEXxuA6UMxqswRYppz/5cSPdOcgqF5FIrvARiagGsD3ApHkmfDtW0BjYHkZhxIMFqLvuVKuYquAbw9cvDmkGP/mOPgmGPviOPVR46TnwMMrJFDckBbpHQ3NzeFKAwC0rnm8gV8qVRxrQDPDzie7vl48c7Hyw8+nu17ePLax/uTIYLBCDg9PR0W5nLivKI5vSibzcZbDmjLAxPgNjAciYe6s+WHr68wlCEHehQKc2KpzA5DYTZ0zX7cBRu1DTU29WHYJtFtE7ZLdMk+yiG11Xm5jMBzoZgeujbgey4sy0YQBLAsC5y7YkwAM5kMpqamhGgerZPDy8tL0M/zfHSUHur1Om5ubiDLMjjn8H0fnufhL6HNJd0yBIQsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20379b0d140455b14db6cfb0dcd1a1c9/e4706/iPhone_Wizard_Installation_9020_9010_d02.avif 230w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/d1af7/iPhone_Wizard_Installation_9020_9010_d02.avif 460w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/7f308/iPhone_Wizard_Installation_9020_9010_d02.avif 920w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/e1c99/iPhone_Wizard_Installation_9020_9010_d02.avif 1380w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/6e1e4/iPhone_Wizard_Installation_9020_9010_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/20379b0d140455b14db6cfb0dcd1a1c9/a0b58/iPhone_Wizard_Installation_9020_9010_d02.webp 230w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/bc10c/iPhone_Wizard_Installation_9020_9010_d02.webp 460w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/966d8/iPhone_Wizard_Installation_9020_9010_d02.webp 920w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/445df/iPhone_Wizard_Installation_9020_9010_d02.webp 1380w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/87a8c/iPhone_Wizard_Installation_9020_9010_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20379b0d140455b14db6cfb0dcd1a1c9/81c8e/iPhone_Wizard_Installation_9020_9010_d02.png 230w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/08a84/iPhone_Wizard_Installation_9020_9010_d02.png 460w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/c0255/iPhone_Wizard_Installation_9020_9010_d02.png 920w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/b1001/iPhone_Wizard_Installation_9020_9010_d02.png 1380w", "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/81315/iPhone_Wizard_Installation_9020_9010_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/20379b0d140455b14db6cfb0dcd1a1c9/c0255/iPhone_Wizard_Installation_9020_9010_d02.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/65ea7015927a983ae6f4b25e94f4759b/81315/iPhone_Wizard_Installation_9020_9010_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADMUlEQVQ4y41U26rkRBTN9/kk+KLgh/giPoi/IYg4+AMi4jyKCOPgMOOcnrGnz0l3ctJJdyqXSt1SqWTJ3um0OR5FA4td2ZdVq4q9K1KdhigFQpjgfcA4gkFr8p3PAm2r4JzH+XTCMIzX2DqvaSTKskJ0Xw/Iqv4ayPMTjscCwzBh8AGd7pHVA4o2IBU9ej9imoCqapGmGdeEYYS1HgfhETWtRdM6SGnRdRZ13TFoTT7ZWbSScua806nC8XhmpGmOohCMttWcF0lpQGjbGUwi7fWffRdYM6AsW+x2e8RxisPhiLu7BHleQusBXecQte2s4O9E63+yda1wc/MHbm622GzeMd6+vWXfZrPFq1dvkOeCCA3+C0TYNBpJkmO7jfH8+Uu8ePGa7bNnv/EmpJbUR3KljiQr1TNovY4RtPbX2JK7WIrxkelik+TIu+92B74fUnF7m+D+/oTkcGRLCteKqViIDkLIB7FIGw9rB24bKTXqWkKIhtfUe51y0Lp/cJ9EcCoqZFl+6Qp1jUV5bZGXLVQnobWGtRbOOfTOwfUeSQuUGjDacRGR7d7FiO8S3riTarUZKVQdqvKM8/mEoihQliWUUkzuXI/KAMpN0GompCMuKknZctyFNLID0DhgmPDoo4lo7QTdT1BEKC039D5OuQ//qb2ic9Vgs0twSDPk+RFZlkEIAaU6KK2xFUDR0ZGp0HGv/fzTL3j9+xso7VftZecjG+NgtIFSC6g9HM8mgYq06dGxCou6MciyEqJSPI70XzfzaDLhwt5epT8eu2WtOwP0BvAOcBajNYCbfd4YJo0ejtuC1SheHgarDDb3Fh9+E/D+VwM+ehLw8bcBH3wd8N6XAU9+7RGseTgp/wZSTeriwuLzHz0+/d6z/eKpx2c/eHzy3YCnG4dem+W1WRM8VrkmhV+hv9hhdeT5zuzqvh7f4xyb/VVjUDWWUTd//TfNXB+tFc3FmrH46KGdH9v/lxdJ2cFqhVJPqKjBe2oZhxACjDHo+55HUVNrdRK1mXA2wBgG9k3TxFPl/cA2GscRhDBOOOYF4jhmUINP0yUWAua8wNNzKgXn7Pd7pGkK7/0170/sM4qZ7bPTLwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/65ea7015927a983ae6f4b25e94f4759b/e4706/iPhone_Wizard_Installation_9020_9010_d03.avif 230w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/d1af7/iPhone_Wizard_Installation_9020_9010_d03.avif 460w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/7f308/iPhone_Wizard_Installation_9020_9010_d03.avif 920w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/e1c99/iPhone_Wizard_Installation_9020_9010_d03.avif 1380w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/6e1e4/iPhone_Wizard_Installation_9020_9010_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/65ea7015927a983ae6f4b25e94f4759b/a0b58/iPhone_Wizard_Installation_9020_9010_d03.webp 230w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/bc10c/iPhone_Wizard_Installation_9020_9010_d03.webp 460w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/966d8/iPhone_Wizard_Installation_9020_9010_d03.webp 920w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/445df/iPhone_Wizard_Installation_9020_9010_d03.webp 1380w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/87a8c/iPhone_Wizard_Installation_9020_9010_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/65ea7015927a983ae6f4b25e94f4759b/81c8e/iPhone_Wizard_Installation_9020_9010_d03.png 230w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/08a84/iPhone_Wizard_Installation_9020_9010_d03.png 460w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/c0255/iPhone_Wizard_Installation_9020_9010_d03.png 920w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/b1001/iPhone_Wizard_Installation_9020_9010_d03.png 1380w", "/en/static/65ea7015927a983ae6f4b25e94f4759b/81315/iPhone_Wizard_Installation_9020_9010_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/65ea7015927a983ae6f4b25e94f4759b/c0255/iPhone_Wizard_Installation_9020_9010_d03.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Wählen Sie, ob Ihre Kamera über `}<strong parentName="li">{`WiFi`}</strong>{` angeschlossen werden soll oder ob Sie ein Ethernet-Kabel verwenden.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/852104634d46855e82f5ef5cc362aef9/81315/iPhone_Wizard_Installation_9020_9010_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADoElEQVQ4y2WVy67jRBCG/RS8ACvYIiEkFrDjzVghGEAMF4kHYcFizmh0ck6udi5Ocm5O4jixYzu+t+8/qnLsyQyLT67urv67uqu6LUVhDMd2UBQV8rxEWdZMa59ODnw/QprmOFnWB35Vhc72vAC27ULauwUMN2NHcjDNE1OWQFlWCOMM+3MB0y+hOxmyrERdA67rQ9cNlBVQFhWEyLF1ckh+kMLzBU6nM2zbw/FoM7btw7JcBIFAEGbs4wUpHMeHaTrso+tHtk/Wmf1IS3LdgAe3WwObjcFOZG+3B+x2RxwONlw3bIQDAcchf4cXJNuyzozvJwiChARDrFbPUJQF5vMVZHmO6XQJRVExnapYr194MkfAkwSiKGMxio7aYZiymB8ISNQgWudrqO96/Jp2V+dz9EG/1BosQjZNjgterRVtheM45+iIJirRtQlqS/8Tsz342g6BF7FoK2YYFh/JYrHGYvGA5fKJj2q1eur6TNN9L0jZ2+lHnPQj/PUTAi/uIqTVn593ePv2FoPBBLe3fdzfj9DrDTAcKri7G2I2W/FZSzTJ8yIum+VijcdHDW6QcJ/nhV2ElPHxeMbJm82WnLzxeNrZw6EMTdMbQZpEqT8cTtjrR3hnEoovvBekyar6wFAF0JfEaBGCyqnbsufFXG8fZ7vdMq1OW5tMZhwZbZ3o98fdl+Z3EfJNaPESnD3BeJcIqcDp8FX1kRNA0VJSKFFUq5QQqk3J9wWSKAHSBHWaoBINZFMfjZGgrpssQmIkQjbR2CvO+n5vQcqTBP+qKb7+vcI3f5b47u+Gb/8q8dXrCv/MU9RZhueXPXq9Ie7uRhgMZOb+fox+f4Lb3gCyvOBjkygCdS/w802OX25y/PGu4debHK/e5JjvBNIkxWZ7wGikQF0sMRnJUCYKFHmK2XSOmTLjW3MpbAFBW84upFd2nvBYEKT8omgbA/OHA+SVgdnjkZmoOsaqjoPlIwpTSgpdLdEl4WN8v0lMngjIuxyf/QR8+gPwxW/Al6+Bz18Bn3wP/Pgm52A4woakuX5X7WtoYfucYKIJjF4ExlrDSBMYPAtsTYGIni9ybGhFmoJu+8Kwgdph2ERKiczimCG7EAlE3NStFAQhRBzBjmu4KVBkKYRIUZYlEpqYZUhTemkSRGEAJ65hxfTsFwijBFVVI4xi5HmBOI4hVRX9SyqUVY3D0YSmaYxhGKjry1hZovFr/ieW7bDPZrOBruvI87zz+w+D1WWKud2IeAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/852104634d46855e82f5ef5cc362aef9/e4706/iPhone_Wizard_Installation_9020_9010_d04.avif 230w", "/en/static/852104634d46855e82f5ef5cc362aef9/d1af7/iPhone_Wizard_Installation_9020_9010_d04.avif 460w", "/en/static/852104634d46855e82f5ef5cc362aef9/7f308/iPhone_Wizard_Installation_9020_9010_d04.avif 920w", "/en/static/852104634d46855e82f5ef5cc362aef9/e1c99/iPhone_Wizard_Installation_9020_9010_d04.avif 1380w", "/en/static/852104634d46855e82f5ef5cc362aef9/6e1e4/iPhone_Wizard_Installation_9020_9010_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/852104634d46855e82f5ef5cc362aef9/a0b58/iPhone_Wizard_Installation_9020_9010_d04.webp 230w", "/en/static/852104634d46855e82f5ef5cc362aef9/bc10c/iPhone_Wizard_Installation_9020_9010_d04.webp 460w", "/en/static/852104634d46855e82f5ef5cc362aef9/966d8/iPhone_Wizard_Installation_9020_9010_d04.webp 920w", "/en/static/852104634d46855e82f5ef5cc362aef9/445df/iPhone_Wizard_Installation_9020_9010_d04.webp 1380w", "/en/static/852104634d46855e82f5ef5cc362aef9/87a8c/iPhone_Wizard_Installation_9020_9010_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/852104634d46855e82f5ef5cc362aef9/81c8e/iPhone_Wizard_Installation_9020_9010_d04.png 230w", "/en/static/852104634d46855e82f5ef5cc362aef9/08a84/iPhone_Wizard_Installation_9020_9010_d04.png 460w", "/en/static/852104634d46855e82f5ef5cc362aef9/c0255/iPhone_Wizard_Installation_9020_9010_d04.png 920w", "/en/static/852104634d46855e82f5ef5cc362aef9/b1001/iPhone_Wizard_Installation_9020_9010_d04.png 1380w", "/en/static/852104634d46855e82f5ef5cc362aef9/81315/iPhone_Wizard_Installation_9020_9010_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/852104634d46855e82f5ef5cc362aef9/c0255/iPhone_Wizard_Installation_9020_9010_d04.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8822fcb357711248e48c06c008c07aee/81315/iPhone_Wizard_Installation_9020_9010_d05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgUlEQVQ4y5VVy24UMRCc3+HKgQsHPpEDEkJIXJEQUhD/wQVQiPawYaNNNrO789h5+DEPu1C148kksCSxVLLX011dbXd7k6o6YLdNYa2FMQZd1wniOk1TFGUJpRTS680du77vp3We59hut0jWmcY6U+isxTAMuLq6EvT9gK6zONQK69wgLS1+7xRaZTAMPbIsw8XFhZBaY9AqhdVeIxmchwegtUbTNDgcDqiqCm3bSnTnPcbRoRfQEqKStrSjH3zYH0aHxFqDtmnEgGBqNIqE3jmM4ygOfd/JPokYOII+DMKR8IzOzs7krDi894K4ds6hNYGQR1KWpaR7fn6OxWIhftyjGCEke13XEomK6MRzIbiW38MoqouimJTEoPPBvWSz2YjC5XIpJBxUFZVyrXuPPC/kFmkTv0XwSAghjJHczYdJ1Y3CkeTOwdpQSrz9+4RzARNhZw3KIkeR71FXJbL9Fp3VUzq8jN1uF271SMpyhoCHGTxqCxyMR6G9zE0HVAawg58uhOfN+V8KmRHnRNQJoUdjIcSVzIF4TkinWELzipirTW5/+P8A0l4sDaZOMPXoy8Jn6XAvCZGO4TY61ZGoaRXqRkFpdhEE7JC2DcWd4DHjyAXMM5hdykNcXlTQcZl7vHzn8fxNmF+9B168BZ699vjwLZAnTxHIC/tyCnz6AZycAl9/AZ9/Ah+/A4v9IxU+mPVsMJPkfk0dA9ORNnN/g0+g809I+SkjYXUHhIc0Nvq8P2M3THburt2dXmaxGt0iVx6lBcbewlgbXhmtp/8YpTVUW6PUHhkfaTfIHkdsSSnsGIVncZ1usVqtBHzW3D0l4YkCdvtcbPifcnl5OfUxv/8BWLGzJOEqs+oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8822fcb357711248e48c06c008c07aee/e4706/iPhone_Wizard_Installation_9020_9010_d05.avif 230w", "/en/static/8822fcb357711248e48c06c008c07aee/d1af7/iPhone_Wizard_Installation_9020_9010_d05.avif 460w", "/en/static/8822fcb357711248e48c06c008c07aee/7f308/iPhone_Wizard_Installation_9020_9010_d05.avif 920w", "/en/static/8822fcb357711248e48c06c008c07aee/e1c99/iPhone_Wizard_Installation_9020_9010_d05.avif 1380w", "/en/static/8822fcb357711248e48c06c008c07aee/6e1e4/iPhone_Wizard_Installation_9020_9010_d05.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8822fcb357711248e48c06c008c07aee/a0b58/iPhone_Wizard_Installation_9020_9010_d05.webp 230w", "/en/static/8822fcb357711248e48c06c008c07aee/bc10c/iPhone_Wizard_Installation_9020_9010_d05.webp 460w", "/en/static/8822fcb357711248e48c06c008c07aee/966d8/iPhone_Wizard_Installation_9020_9010_d05.webp 920w", "/en/static/8822fcb357711248e48c06c008c07aee/445df/iPhone_Wizard_Installation_9020_9010_d05.webp 1380w", "/en/static/8822fcb357711248e48c06c008c07aee/87a8c/iPhone_Wizard_Installation_9020_9010_d05.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8822fcb357711248e48c06c008c07aee/81c8e/iPhone_Wizard_Installation_9020_9010_d05.png 230w", "/en/static/8822fcb357711248e48c06c008c07aee/08a84/iPhone_Wizard_Installation_9020_9010_d05.png 460w", "/en/static/8822fcb357711248e48c06c008c07aee/c0255/iPhone_Wizard_Installation_9020_9010_d05.png 920w", "/en/static/8822fcb357711248e48c06c008c07aee/b1001/iPhone_Wizard_Installation_9020_9010_d05.png 1380w", "/en/static/8822fcb357711248e48c06c008c07aee/81315/iPhone_Wizard_Installation_9020_9010_d05.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8822fcb357711248e48c06c008c07aee/c0255/iPhone_Wizard_Installation_9020_9010_d05.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Wählen Sie Ihre Kamera aus der Liste aus. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3032467ea81f895d2ff1200cd6e74bf7/81315/iPhone_Wizard_Installation_9020_9010_d06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACr0lEQVQ4y+2VTYskNRjH67N48+BNL948CHryg4m6wsBeRAU/hCh4WncRb4KozNA9MzvVXV3vlXpJUlVJfpKke7YX2UbvBn7kpVL//PMkPEmE6CjyA1prlFLM8xw4tQ+HA03botVMkResq2VZDMY4rOWx3XWCsmpIHirFQzUxa826rmRZxn6/Z1kW5lnT9SNprSh6w65ZmBeLc9C2gjTdB1G/iNYrabOSrMZiHEgpA8Mw0Pf9Y98XYyxF2XEoOoqiIc9rsqzi4eFAnjeUZUdVdeRFS+KsZRxHuq6jaRratg31qe23Dg7R9ex3GXXd0vfjI76fZTn7/YGqqkm8g7wo2G633N7ecnNzw/X1dag3m02IoS9e2IeiqirOy2m8LEukkiTend+iEAJjDG8qPhS73Y6maVnWFWsd1jmk9IIx7tM0kfgVN5stL9M9al5Z/ckZx2ocejGsx0WmaQyC86yOS7gjILo2CPqbkhh/TG71oT+bdE481WmSlGVFUXWM2jIo80heCYqiQikdY/hrCh9+DR99A598F/n4W/jgK3hxH93MUvPby5l3rxxvf25578ry/lPLO08sb33quHq2gpZe0HHXOL742fHkuePpL5Evnzs+e+bY1lFwHDV5LfnpT80Pf2h+POP73zV/7RTTIEmc40KJH33wh0Ez9BKrJE6/jnemRokQKm7ZOsJheMyR2Hfhm3PRoRCSVija7p90QtH3Mgo69yaiw3PBvlcXSS5u+H/BfyXoJ1/isqD8bw7Pnfp7GASPCBEFRGi/IrHWEnEhkfqM4zmNeTFfj+MrR/HnKXAaG4ZI4tOSkiP15Gg1mEWjtA6CPmOf3hifHMahp5WOUoI1axjzC/q0tSxrTF8nN8Y6skPO3d1dwKejMP6aYxMufFHVYc79/T1pmob35zT3b5XPmaCYQnsLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3032467ea81f895d2ff1200cd6e74bf7/e4706/iPhone_Wizard_Installation_9020_9010_d06.avif 230w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/d1af7/iPhone_Wizard_Installation_9020_9010_d06.avif 460w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/7f308/iPhone_Wizard_Installation_9020_9010_d06.avif 920w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/e1c99/iPhone_Wizard_Installation_9020_9010_d06.avif 1380w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/6e1e4/iPhone_Wizard_Installation_9020_9010_d06.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3032467ea81f895d2ff1200cd6e74bf7/a0b58/iPhone_Wizard_Installation_9020_9010_d06.webp 230w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/bc10c/iPhone_Wizard_Installation_9020_9010_d06.webp 460w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/966d8/iPhone_Wizard_Installation_9020_9010_d06.webp 920w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/445df/iPhone_Wizard_Installation_9020_9010_d06.webp 1380w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/87a8c/iPhone_Wizard_Installation_9020_9010_d06.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3032467ea81f895d2ff1200cd6e74bf7/81c8e/iPhone_Wizard_Installation_9020_9010_d06.png 230w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/08a84/iPhone_Wizard_Installation_9020_9010_d06.png 460w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/c0255/iPhone_Wizard_Installation_9020_9010_d06.png 920w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/b1001/iPhone_Wizard_Installation_9020_9010_d06.png 1380w", "/en/static/3032467ea81f895d2ff1200cd6e74bf7/81315/iPhone_Wizard_Installation_9020_9010_d06.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3032467ea81f895d2ff1200cd6e74bf7/c0255/iPhone_Wizard_Installation_9020_9010_d06.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      